<template>
  <div v-loading="pageloading">
    <configuration @save = "save('ruForm')" img="/image/20221114-fed09ff9-db37-4645-8cb3-b3a6ec6c053d.png">
      <el-form :model="ruForm" :rules="rules" ref="ruForm" label-width="150px">
        <div>
          <div class = "titlefont">入口配置</div>
          <div class= "grayline"></div>
          <el-form-item prop="QyWeixinContactMeId" label="选择员工活码：">
            <el-select v-model="ruForm.QyWeixinContactMeId" placeholder="选择员工活码" popper-class = "seloption-pad" :popper-append-to-body="false"
            style="width:350px" :loading = "selloading" ref="select" filterable>
              <div class = "empty-topRight" v-if="QyWeixinContactMeIdlist&&QyWeixinContactMeIdlist.length">
                  <el-button type="text" class = "topRight-button" @click="jumplist">添加员工活码</el-button>
              </div>
              <el-option v-for="(v,i) in QyWeixinContactMeIdlist" :key="v.Id" :value="v.Id" :label="v.Remark"></el-option>
              <div slot="empty">
                <div class = "nolist">
                  查询不到相关员工活码数据，<el-button type="text" @click="jumplist">点击前往创建</el-button>
                </div>
              </div>
            </el-select>
            <div class = "smallfont" style="margin:5px 0px">客户进入商品详情页，点击添加好友的入口，企业微信将推送活码中配置的适用员工企业微码</div>
          </el-form-item>
          <el-form-item prop="EntranceStyleType" label="入口样式配置：">
            <el-radio-group v-model="ruForm.EntranceStyleType">
              <el-radio :label="1">系统默认样式</el-radio>
              <el-radio :label="2">自定义上传入口图片</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="90px" v-if="ruForm.EntranceStyleType==1">
            <el-form-item label="标签文案：">
              <el-input v-model="ruForm.LabelText" placeholder="加好友" style="width:300px" maxlength="4"></el-input>
            </el-form-item>
            <el-form-item label="加好友引导描述：" style="margin:20px 0px">
              <el-input v-model="ruForm.AddFriendGuideDescription" placeholder="添加企微好友，获得更多专属权益" style="width:300px" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="按钮文案：">
              <el-input v-model="ruForm.ButtonText" placeholder="立即添加" style="width:300px" maxlength="4"></el-input>
            </el-form-item>
          </el-form-item>
          <el-form-item v-if="ruForm.EntranceStyleType==2" prop="StyleImgUrl" label-width="150px" :rules="rules.StyleImgUrl" :key="'StyleImgUrl'">
            <el-upload class=" uploadClass" :action="imgApi" :before-upload="beforeUpload"
						:on-success="handleUploadSuccess" accept="image/*" style=""
						:show-file-list="false">
              <img v-if="ruForm.StyleImgUrl" :src="imgUrl + ruForm.StyleImgUrl" class="uploadClass">
              <i v-else class="el-icon-plus" style="font-size:20px"></i>
            </el-upload>
            <div class = "smallfont" style="margin:5px 0px">图片建议宽高比2:1，图片大小请控制在1M以内</div>
          </el-form-item>
        </div>
        <div>
          <div class = "titlefont" style="margin-top:30px">加好友权益</div>
          <div class= "grayline"></div>
          <el-form-item label="加好友权益：" prop="AddFriendBenefitType" :key="'AddFriendBenefitType'">
            <div class = "smallfont" style="margin-top:10px">
              客户成功添加员工企微为好友后，可获得的权益；每个客户仅可获得1次权益，客户在使用权益前删除员工企业微信，权益失效。
              <el-link type="primary" :underline="false" href="https://jusnn6k8al.feishu.cn/docs/doccnheaE1apvneGJ5USdq7TODc"  target="_blank"
              style="font-size:12px;">点击查看好友权益详细规则</el-link>
            </div>
            <el-radio-group v-model="ruForm.AddFriendBenefitType" style="width:100%;margin-top:20px" @change="changetype">
              <div>
                <el-radio :label="0">不发放权益</el-radio>
              </div>
              <div class = "flexRow" style="margin:20px 0px">
                <el-radio :label="1">下单减
                  <el-form-item label-width="0px" :key="'MarkdownMoney'" prop="MarkdownMoney" style="display:inline-block">
                    <el-input :disabled="ruForm.AddFriendBenefitType!=1" @change="blurMoney" :controls="false" v-model="ruForm.MarkdownMoney" style="width:200px;margin:0px 10px;text-align:center"></el-input>元
                  </el-form-item>
                </el-radio>
              </div>
              <div class = "flexRow">
                <el-radio :label="2">下单打
                  <el-form-item label-width="0px" :key="'DicsountRate'" prop="DicsountRate" style="display:inline-block">
                    <el-input :disabled="ruForm.AddFriendBenefitType!=2" @change="blurRate" :controls="false" v-model="ruForm.DicsountRate" style="width:200px;margin:0px 10px"></el-input>折
                  </el-form-item>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="优惠叠加：" v-if="ruForm.AddFriendBenefitType!=0">
            <el-checkbox-group v-model="ruForm.OverlayActivityTypeList">
              <el-checkbox :label="2">满减送活动</el-checkbox>
              <el-checkbox :label="3">打包一口价</el-checkbox>
              <el-checkbox :label="11">多人拼团</el-checkbox>
            </el-checkbox-group>
            <div class = "smallfont">
              活动：限时折扣、助力领取、积分换购和好友权益不叠加      
              <el-link type="primary" :underline="false" href="https://jusnn6k8al.feishu.cn/docs/doccnheaE1apvneGJ5USdq7TODc"  target="_blank"
              style="font-size:12px;">点击查看好友优惠详细规则</el-link>
            </div>
          </el-form-item>
        </div>
        <div>
          <div class = "titlefont" style="margin:-top:30px">加好友欢迎语</div>
          <div class= "grayline"></div>
          <el-form-item label="欢迎语：">
            <el-switch v-model="ruForm.IsOpenWelcome"></el-switch>
            <div class = "smallfont" style="margin:5px 0px">1.开启后，客户在商品详情页内点击加好友入口并成功添加员工企微，将会发送此处配置的欢迎语</div>
            <div class = "smallfont">2.若关闭，始终发送好友欢迎语中，员工适用的欢迎语</div>
            <div v-if="ruForm.IsOpenWelcome" style="margin:10px 0px">
              <welcome ref="welcome" :Welcome="ruForm.Welcome" @gethtml = "gethtml"  :rule="rules.Welcome"></welcome>
            </div>
          </el-form-item>
          <el-form-item label="商品链接：" v-if="ruForm.IsOpenWelcome">
            <el-checkbox v-model="ruForm.IsAutoSendProductLink">自动发送</el-checkbox>
            <div class = "smallfont">选中自动发送后，客户成功添加员工企微为好友，跟随欢迎语内容，同时发送客户浏览的商品小程序卡</div>
          </el-form-item>
        </div>
      </el-form>
    </configuration>
  </div>
</template>

<script>
import configuration from "./components/configuration"
import config from "@/config"
import welcome from "./components/welcome"
import{
  qyWeixinContactMeListRequest,
  qyWeixinIncreaseFansedit,
  qyWeixinIncreaseFanslist
}from "@/api/sv1.0.0"
export default {
  components:{
    configuration,
    welcome
  },
  data () {
    var checkAddFriendBenefitType = (rule, value, callback) => {
      if(!value&&value!=0){
        callback(new Error('请选择加好友权益'))
      }else{
        callback()
      }
    }
    var checkMarkdownMoney = (rule, value, callback) => {
      let two = /^\d+(\.\d{1,2})?$/
      if(this.ruForm.AddFriendBenefitType!=1){
        callback()
      }else if(value>=0.01&&value<=9999&&two.test(value)){
        callback()
      }else{
        callback(new Error('金额请输入在0.01~9999元之间，最多2位小数'))
      }
    }
    var checkDicsountRate = (rule, value, callback) => {
      // console.log(value,1)
      let two = /^\d+(\.\d{1})?$/
      if(this.ruForm.AddFriendBenefitType!=2){
        callback()
      }else if(value>=1&&value<=9.9&&two.test(value)){
        callback()
      }else{
        callback(new Error('折扣请输入在1~9.9折之间，最多1位小数'))
      }
    }
    var checkWelcome = (rule, value, callback) => {
      // console.log(this.filterlabel(value))
      let testnull = /^ [\s]*$/
      // console.log(!testnull.test(value))
      if(!this.filterlabel(value)||testnull.test(value)){
        callback(new Error('欢迎语文本内容不可为空'))
      }else{
        callback()
      }
    }
    return {
      pageloading:false,
      imgApi: config.UPLOAD_IMG,
			imgUrl: config.IMG_BASE,
      ruForm:{
        Id:0,
        PageType:1,
        QyWeixinContactMeId:'',
        EntranceStyleType:1,
        LabelText:'',
        AddFriendGuideDescription:'',
        ButtonText:'',
        StyleImgUrl:'',
        AddFriendBenefitType:1,
        MarkdownMoney:undefined,
        DicsountRate:undefined,
        OverlayActivityTypeList:[],
        IsOpenWelcome:true,
        Welcome:'',
        IsAutoSendProductLink:true
      },
      rules:{
        QyWeixinContactMeId:[
          { required: true, message: '请从已保存的员工活码中选择一个', trigger: 'blur' },
        ],
        StyleImgUrl:[
          { required: true, message: '请上传入口图片', trigger: 'blur' },
        ],
        EntranceStyleType:[
          { required: true, message: '选择入口样式配置', trigger: 'blur' },
        ],
        AddFriendBenefitType:[
           { required: true,validator: checkAddFriendBenefitType, trigger: 'blur' },
        ],
        MarkdownMoney:[
          { required: true,validator: checkMarkdownMoney, trigger: 'blur' },
        ],
        DicsountRate:[
          // { required: true, message: '折扣请输入在1~9.9折之间，最多1位小数', trigger: 'blur' },
           { required: true,validator: checkDicsountRate, trigger: 'blur' },
        ],
        Welcome:[
					{ required: true, message: '欢迎语文本内容不可为空', trigger: 'blur' },
          { required: true, validator: checkWelcome, trigger: 'blur' },
				]
      },
      selloading:false,
      QyWeixinContactMeIdlist:[]
    }
  },
  created () {
    this.getqyWeixinContactMeListRequest()
    // this.$nextTick(()=>{
    //   this.getrisefanslist()
    // })
  },
  methods: {
    filterlabel(e){
			// console.log(e)
				let str  = JSON.parse(JSON.stringify(e))
				let newe = str.replace(/<[^<>]+>/g,'')
				// console.log(newe)
				return newe
    },
    blurMoney(e){
      // console.log(!Number(e))
      if(!Number(e)){
        this.ruForm.MarkdownMoney = undefined
      }
    },
    gethtml(html){
      this.ruForm.Welcome = html
    },
    changetype(e){
      // console.log(e)
      this.ruForm.AddFriendBenefitType = e
    },
    blurRate(e){
      // console.log(this.ruForm.AddFriendBenefitType)
      if(!Number(e)){
        this.ruForm.DicsountRate = undefined
      }
      
    },
    save(formName){
      // console.log(this.$refs.welcome)
      if(this.$refs.welcome){
        this.ruForm.Welcome = this.$refs.welcome.returnhtml().Welcome
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.tosave()
        } else {
          this.$message.error('请完善配置')
          this.$nextTick(()=>{
              let iserror = document.getElementsByClassName('is-error')
              iserror[0].scrollIntoView({
                block:'center',
                behavior:'smooth'
              })
          })
          return false;
        }
      });
    },
    async tosave(){
      this.pageloading=true
      try{
        let data = JSON.parse(JSON.stringify(this.ruForm))
        if(data.EntranceStyleType==1){
          data.StyleImgUrl=''
          if(!data.LabelText){
            data.LabelText='加好友'
          }
          if(!data.AddFriendGuideDescription){
            data.AddFriendGuideDescription='添加企微好友，获得更多专属权益'
          }
          if(!data.ButtonText){
            data.ButtonText='立即添加'
          }
        }else if(data.EntranceStyleType==2){
          data.LabelText=''
          data.AddFriendGuideDescription='',
          data.ButtonText=''
        }
        if(data.IsOpenWelcome){
          data.Welcome = this.filterlabel(data.Welcome)
        }else{
          data.Welcome = ''
          data.IsAutoSendProductLink=true
        }
        if(data.AddFriendBenefitType==0){
          data.MarkdownMoney=''
          data.DicsountRate=''
          data.OverlayActivityTypeList=[]
        }else if(data.AddFriendBenefitType==1){
          data.DicsountRate=''
        }else if(data.AddFriendBenefitType==2){
          data.MarkdownMoney=''
        }
        let newdata = {
          QyWeixinIncreaseFans:data
        }
        let result = await qyWeixinIncreaseFansedit(newdata)
        if(result.IsSuccess){
          this.$message.success('保存成功')
          this.$router.go(-1)
          // this.$router.push({
          //   paht:'/qyCustomer/risefans'
          // })
        }
      }finally{
        this.pageloading=false
      }
    },
    beforeUpload(file){
      const isLt1M = file.size / 1024 / 1024 < 1;
				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!isLt1M) {
					this.$message.error('图片大小请控制在1M以内')
					return false
				}
			return true;
    },
    handleUploadSuccess(file){
      this.ruForm.StyleImgUrl = file[0]
    },
    jumpcreated(){
      this.$router.push({
        path:'/qyCustomer/callmeedit?Id=0'
      })
    },
    jumplist(){
      this.$router.push({
        path:'/qyCustomer/callme'
      })
    },
    async getqyWeixinContactMeListRequest(){
      this.selloading = true
      try{
        let data = {
          Skip:0,
          Take:99999,
          OrderBy:'AddTime',
        }
        let result = await qyWeixinContactMeListRequest(data)
        if(result.IsSuccess){
          this.QyWeixinContactMeIdlist = result.Result.Results
        }
        this.getrisefanslist()
      }finally{
        this.selloading = false
      }
    },
    async getrisefanslist(){
      this.pageloading = true
      try{
        let res = await qyWeixinIncreaseFanslist()
        if(res.IsSuccess){
          let message = res.Result.find((v)=>{
            return v.PageType == 1
          })
          if(message){
               let msg =  this.QyWeixinContactMeIdlist.some((v)=>{
                  return v.Id==message.QyWeixinContactMeId
                })
                if(!msg){
                  message.QyWeixinContactMeId = ''
                }
            if(message.AddFriendBenefitType==0){
              message.MarkdownMoney=undefined
              message.DicsountRate=undefined
              message.OverlayActivityTypeList=[]
            }else if(message.AddFriendBenefitType==1){
              message.DicsountRate=undefined
            }else if(message.AddFriendBenefitType==2){
              message.MarkdownMoney=undefined
            }
            if(message.IsOpenWelcome){
              message.Welcome = message.Welcome.replace(/#客户微信昵称#/g, ' <span style="color: #409EFF;">#客户微信昵称#</span> ').replace(/#员工姓名#/g, ' <span style="color: #409EFF;">#员工姓名#</span> ') 
            }
            this.ruForm = message
            // console.log(this.ruForm.Welcome,123)
          }
        }
      }finally{
        this.pageloading = false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
.titlefont{
  font-size: 15px;
  font-weight: bold;
}
.smallfont{
  font-size: 12px;
  color: #999999;
  line-height: 1.3;
}
.grayline{
  width:100%;
  height: 1px;
  background: #d5d5d5;
  transform: scale(1,.5);
  margin:20px 0px
}
.empty-topRight{
  width:100%;
    /* border:1px solid black; */
    text-align:right;
    margin-bottom:5px;
    .topRight-button{
      margin:0px;
      padding:0px;
      margin-right:10px
    }
}
.empty{
  padding:10px 0px;
  /* border:1px solid red; */
  max-width: 350px;
  .empty-topRight{
    width:100%;
    /* border:1px solid black; */
    text-align:right;
    margin-bottom:5px;
    .topRight-button{
      margin:0px;
      padding:0px;
      margin-right:10px
    }
  }
  .empty-optionBox{
    /* border:1px solid black; */
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    .empty-option-font{
      flex:1;
      font-size:14px;
      line-height: 1.5;
      padding: 5px 15px;
      width: 100%;
      font-weight: 100;
    }
    .selectedfont{
      color:#409eff;
      font-weight: bold;
    }
  }
  .empty-optionBox:hover{
    background: #F5F7FA;
  }
}
.nolist{
  padding: 10px;
  text-align: center;
  font-size: 12px;
  color: #909299;
}
.maxoption{
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
}
.fonthidden{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.uploadClass{
  width: 400px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border:1px dashed #DCDFE6;
  border-radius: 3px;
  /* border:1px solid red */
}
.uploadClass ::v-deep .el-upload{
  display:block !important
}
</style>
<style lang = "less">

.seloption-pad ::v-deep .el-select-dropdown__list{
    padding:10px 0px !important
  }
</style>
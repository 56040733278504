<template>
  <div>
    <el-card class = "card">
      <div class = "flexRow">
        <div class = "imgbox">
          <img :src="imgApi+img" style="width:330px" alt="">
        </div>
        <div style="margin-left:20px;flex:1">
          <slot></slot>
        </div>
      </div>
    </el-card>
    <el-card class = "cardbuttom">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </el-card>
  </div>
</template>

<script>
import config from "@/config"
export default {
  props: {
    img:{
      type:String,
      default:''
    }
  },
  data () {
    return {
      imgApi:config.IMG_BASE
    }
  },
  methods: {
    cancel(){
      this.$router.push({
        path:'/qyCustomer/risefans'
      })
    },
    save(){
      this.$emit('save')
    },
  }
}
</script>

<style lang = "less" scoped>
.card{
  /* position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px; */
  padding-bottom: 100px;
}
.cardbuttom{
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height:80px;
  z-index: 999;
  /* border:1px solid black */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
}
/* .overflowY{
  height: 100%;
  overflow: hidden;
  overflow-y:auto;
  border:1px solid black
} */
.flexRow{
  display: flex;
  /* flex-direction: row; */
}
.imgbox{
  width: 350px;
}
</style>